import React, { useContext, useEffect, useState } from 'react';
import { BuilderContext, useDrawer } from 'react-flow-builder';
import { Form, Button, Input, Select, message, Spin } from 'antd';
// import { AvailableWorkflows } from '../../../data/schema/workflows';
// import { workflowList } from '../../../data/schema/workflows';
// import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { GlobalStateContext } from '../../../context/GlobalContextProvider';
import Editor from '@monaco-editor/react';

const ConfigForm = () => {
    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId, projects } = state;
    const [availableWorkflows, setAvailableWorkflows] = useState([]);
    const [userEndpoints, setUserEndpoints] = useState([]);
    const [loadingEndpoints, setLoadingEndpoints] = useState(false);
    const { selectedNode: node } = useContext(BuilderContext);
    const [workflowVal, setWorkflowVal] = useState("");
    const [selectedWorkFlow, setSelectedWorkFlow] = useState('');

    const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

    const [form] = Form.useForm();

    const handleEditorChange = (value) => {
        form.setFieldsValue({
            body: value,
        });
    };

    // Fetch user endpoints for the selected API
    const fetchUserEndpoints = async (appId, appEndpoint) => {
        if (!appId || !appEndpoint || !selectedProjectId || !user?.uid) return;
        setLoadingEndpoints(true);
        try {
            const response = await axios.post(
                `${process.env.GATSBY_BASE_URL}/getAppList/${appId}`,
                { uid: user.uid, projectId: selectedProjectId },
                { headers: { 'Content-Type': 'application/json' } }
            );
            
            if (response.data && Array.isArray(response.data)) {
                const endpointOptions = response.data.map(api => {

                    const endpoint = appEndpoint.replace("{{cloud_name}}", api.cloud_name).replace("{{token}}", api.token);
                    return {
                        label: api.apis_info.name,
                        value: endpoint
                    };
                });
                setUserEndpoints(endpointOptions);
            } else {
                setUserEndpoints([]);
            }
        } catch (error) {
            console.error("Error fetching endpoints:", error);
            message.error("Failed to load endpoints");
            setUserEndpoints([]);
        } finally {
            setLoadingEndpoints(false);
        }
    };
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const params = {};
            selectedWorkFlow.forEach((item) => {
                if (item?.parameters) {
                    item.parameters.forEach((param) => {
                        if (values[param.name]) {
                            params[param.name] = values[param.name];
                        }
                    });
                }
            });

            save?.({ ...values, params });
        } catch (error) {
            const values = form.getFieldsValue();
            save?.(values, !!error);
        }
    };

    const onWorkFlowChange = async (workflow) => {
        setWorkflowVal(workflow);
        const selected = availableWorkflows.find(item => item.value === workflow);
        setSelectedWorkFlow([selected]);

        // Fetch endpoints for the selected API
        if (selected && selected.app_id) {
            fetchUserEndpoints(selected.app_id, selected.endpoint);
        }

        //  form.resetFields(Object.keys(form.getFieldsValue()).filter(key => key !== "step_name"));
        form.setFieldsValue({
            request_type: selected?.request_type,
            endpoint: '' // Reset endpoint when workflow changes
        });
    }

    useEffect(() => {
        let isMounted = true;

        if (!selectedProjectId || !user?.cloud_name) return;

        axios.get(`${process.env.GATSBY_BASE_URL}/api-app-workflows`, {
            params: { projectId: selectedProjectId, cloud_name: user.cloud_name },
            headers: { 'Content-Type': 'application/json' },
        })
            .then((result) => {
                if (isMounted) {
                    setAvailableWorkflows(result.data);
                }
            })
            .catch(() => {
                message.error('Bot OAuth Access Token is not valid.');
            });

        return () => {
            isMounted = false;
        };
    }, [selectedProjectId, user.cloud_name, projects]);

    useEffect(() => {
        if (node?.data?.step_name && availableWorkflows.length > 0) {
            setWorkflowVal(node.data.step_name);

            const selected = availableWorkflows.find(item => item.value === node.data.step_name);
            if (selected) {
                setSelectedWorkFlow([selected]);
                // Fetch endpoints for the selected API when editing an existing node
                if (selected.app_id) {
                    fetchUserEndpoints(selected.app_id, selected.endpoint);
                }
            }
        }
    }, [node?.data?.step_name, availableWorkflows]);

    return (
        <div>
            <Form form={form} onFinish={handleSubmit} initialValues={{ ...node?.data , ...node?.data?.params }} layout="vertical"
            >

                <Form.Item name="step_name" label="Step Name" rules={[{ required: true, message: "Step name is required" }]}>
                    <Select
                        showSearch
                        options={availableWorkflows}
                        onChange={onWorkFlowChange}
                        placeholder="Select a workflow"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
                {
                    workflowVal &&
                    selectedWorkFlow.map((item, index) => (
                        <div key={index}>
                            <>
                                <Form.Item
                                    key={item?.endpoint}
                                    name="endpoint"
                                    label="API"
                                    rules={[{ required: true }]}
                                >
                                    {loadingEndpoints ? (
                                        <Spin size="small" />
                                    ) : (
                                        <Select
                                            placeholder="Select an endpoint"
                                            options={userEndpoints}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    key={item?.request_type}
                                    name="request_type"
                                    initialValue={item?.request_type}
                                    hidden
                                    rules={[{ required: true }]}
                                >
                                </Form.Item>
                                {item.parameters?.map((paramValue, paramIndex) => {
                                    if (paramValue.in) {
                                        return (
                                            <Form.Item
                                                key={`${index}_${paramIndex}`}
                                                className='two-column'
                                                label={paramValue.name}
                                                name={paramValue.name}
                                                validateMessages=''
                                                rules={[
                                                    {
                                                        required: paramValue.required,
                                                    },
                                                ]}
                                                extra={
                                                    <div
                                                        style={{
                                                            fontWeight: 300,
                                                            fontSize: 14,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: paramValue.description,
                                                        }}
                                                    />
                                                }
                                            >
                                                {paramValue.name === 'location' && Array.isArray(paramValue.enum) ? (
                                                    <Select
                                                        placeholder="Select a location"
                                                        style={{ borderRadius: 0 }}
                                                    >
                                                        {paramValue.enum.map((location) => (
                                                            <Select.Option key={location} value={location}>
                                                                {location}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                ) : paramValue.in !== 'body' ? (
                                                    <Input placeholder='Value' style={{ borderRadius: 0, padding: '4px 4px 5px 4px' }} />
                                                ) : (
                                                    <div>
                                                        {/* Editor for body JSON */}
                                                        <Editor
                                                            width="800"
                                                            height="30vh"
                                                            language="json"
                                                            theme="vs"
                                                            value={node?.data?.params?.body??JSON.stringify(paramValue.sampleData, null, 2)}
                                                            options={{ selectOnLineNumbers: true, minimap: { enabled: false } }}
                                                            onChange={handleEditorChange}
                                                        />
                                                    </div>
                                                )}

                                            </Form.Item>
                                        )
                                    }
                                    return null;


                                })}

                            </>
                        </div>
                    ))
                }
                <Form.Item>
                    <Button onClick={cancel} htmlType="button" style={{ marginRight: "14px" }}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>

        </div >
    );
};

export default ConfigForm;

