import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import FlowBuilder, {
    NodeContext,
} from "react-flow-builder";
import WorkflowForm from "./workflowForm";
import ConfigForm from "./configForm";
import { Drawer, Popconfirm, Popover, message } from "antd";
import { GlobalStateContext } from '../../../context/GlobalContextProvider';
import { v4 as uuidv4 } from 'uuid';


import "./index.css";


const StartNodeDisplay = () => {
    const node = useContext(NodeContext);
    return <div className="start-node">{node.name}</div>;
};

const EndNodeDisplay = () => {
    const node = useContext(NodeContext);
    return <div className="end-node">{node.name}</div>;
};

const NodeDisplay = () => {
    const node = useContext(NodeContext);
    return (
        <div
            className={`other-node ${node.configuring ? "node-configuring" : ""} ${node.validateStatusError ? "node-status-error" : ""
                }`}
        >
            {node.data ? node.data.step_name : node.name}
        </div>
    );
};



const registerNodes = [
    {
        type: "start",
        name: "start",
        displayComponent: StartNodeDisplay,
        isStart: true
    },
    {
        type: "end",
        name: "end",
        displayComponent: EndNodeDisplay,
        isEnd: true
    },
    {
        type: "node",
        name: "New Endpoint",
        displayComponent: NodeDisplay,
        configComponent: ConfigForm,
        removeConfirmTitle: "Delete?"
    },
];




const NodeForm = ({ location, workflowId }) => {
    const [nodes, setNodes] = useState([]);
    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId } = state;
    const [registeredNodes, setRegisteredNodes] = useState(registerNodes);
    const [workflowName, setWorkflowName] = useState('')

    useEffect(async () => {
        if (workflowId) {
            try {
                let existingNodes = [];
                existingNodes.push({
                    type: "start",
                    name: "start",
                    displayComponent: StartNodeDisplay,
                    isStart: true
                })
                const response = await axios.get(
                    `${process.env.GATSBY_BASE_URL}/workflows/${workflowId}`,
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                if (response.data && response.data.workflow && response.data.workflow.workflow) {
                    setWorkflowName(response.data.workflow.workflow_name);
                    response.data.workflow.workflow.forEach((data, index) => {
                        existingNodes.push({
                            id: uuidv4(),
                            name: 'New Endpoint',
                            path: [`${(index + 1).toString()}`],
                            type: "node",
                            data: {
                                ...data,
                                step_name: data.step_name,
                            },
                            configuring: false,
                            validateStatusError: false
                        })
                    })
                    existingNodes.push({
                        type: "end",
                        name: "end",
                        displayComponent: EndNodeDisplay,
                        isEnd: true
                    },);
                    setNodes(existingNodes);
                }
            } catch (err) {

            }

        }
    }, [workflowId])



    const handleChange = (nodes) => {
        setNodes(nodes);
    };


    const saveWorkflow = (formData) => {
        const { cloud_name, _id } = user;

        const fields = [];
        if (nodes.length > 2) {
            nodes.map((node) => {
                if (node.type === 'node') {
                    fields.push({
                        step_name: node.data.step_name,
                        endpoint: node.data.endpoint,
                        request_type: node.data.request_type,
                        params: node.data.params || {}
                    });
                }
            })
        }
        axios.post(`${process.env.GATSBY_BASE_URL}/workflows`, {
            name: formData.workflow_name,
            cloud_name: cloud_name,
            projectId: selectedProjectId,
            workflows: fields
        })
            .then((response) => {
                if (response.data.data) {
                    message.success("Workflow updated sucessfully")
                }
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    message.error(err.response.data.message || "workflow update error");
                }
            })

    }

    const updateWorkflow = (formData) => {
        const { cloud_name } = user;
        const fields = nodes
            .filter(node => node.type === 'node')
            .map(node => ({
                step_name: node.data.step_name,
                endpoint: node.data.endpoint,
                request_type: node.data.request_type,
                params: node.data.params || {} // Ensure params is passed correctly
            }));
        axios.put(`${process.env.GATSBY_BASE_URL}/workflows/${workflowId}`, {
            name: formData.workflow_name,
            cloud_name: cloud_name,
            projectId: selectedProjectId,
            workflows: fields
        })
            .then((response) => {
                if (response.data.success) {
                    message.success("Workflow updated sucessfully")
                }
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    message.error(err.response.data.message || "workflow update error");
                }
            })
    }


    return (
        <>
            <FlowBuilder
                nodes={nodes}
                onChange={handleChange}
                registerNodes={registeredNodes}
                historyTool
                zoomTool
                DrawerComponent={(props) => (
                    <Drawer
                        size="large"
                        {...props}
                    >
                        {props.children}
                    </Drawer>
                )}
                PopoverComponent={Popover}
                PopconfirmComponent={Popconfirm}

            />

            <WorkflowForm onSubmit={(values) => { workflowId ? updateWorkflow(values) : saveWorkflow(values) }} workflowName={workflowName} />


        </>
    );
};

export default NodeForm;
